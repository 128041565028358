<template>
  <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }"
              >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
              :to="{ path: '/raceManagement/ibcHomeManagement' }"
              >IBC赛务管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>轮次管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 筛选 -->
      <el-row :gutter="20">
          <el-col :span="12">
              <el-card style="margin-top: 15px">
                  <el-row class="title_row">
                      <el-col>
                          <span>轮次信息筛选</span>
                      </el-col>
                  </el-row>
                  <el-form
                      :model="queryForm"
                      class="filter_Form"
                      label-width="80px"
                      ref="queryFormRef"
                  >
                      <el-row>
                          <el-col :span="12">
                              <el-form-item label="届" prop="ibcSession">
                                  <el-select
                                      placeholder="请选择"
                                      style="width: 100%"
                                      v-model="queryForm.ibcSession"
                                      clearable
                                  >
                                      <el-option
                                          v-for="item in dict_ibcSession"
                                          :key="item.dictCode"
                                          :label="item.dictLabel"
                                          :value="item.dictValue"
                                      ></el-option>
                                  </el-select>
                              </el-form-item>
                          </el-col>
                          <el-col :span="12">
                              <el-form-item label="季度" prop="ibcSeason">
                                  <el-select
                                      placeholder="请选择"
                                      style="width: 100%"
                                      v-model="queryForm.ibcSeason"
                                      @change="seasonChange"
                                      clearable
                                  >
                                      <el-option
                                          v-for="item in dict_ibcSeason"
                                          :key="item.dictCode"
                                          :label="item.dictLabel"
                                          :value="item.dictValue"
                                      ></el-option>
                                  </el-select>
                              </el-form-item>
                          </el-col>
                      </el-row>
                  </el-form>
                  <el-row type="flex" justify="end">
                      <el-button
                          type="primary"
                          @click="editSeasonFormDialogVisible = true"
                          >修改当前赛季</el-button
                      >
                      <el-button type="primary" @click="getDataList('search')"
                          >查询</el-button
                      >
                  </el-row>
              </el-card>
          </el-col>
          <el-col :span="12">
              <el-card style="margin-top: 15px">
                  <el-row class="title_row">
                      <el-col>
                          <div>赛季报名时间管理<span style="color: red">({{selectSeason}})</span></div>
                      </el-col>
                  </el-row>
                  <el-form
                      :model="signInTimeData"
                      class="filter_Form"
                      label-width="120px"
                  >
                      <el-row>
                        <div>报名开始时间: <span style="color: green;">{{signInTimeData.registrationStartTime}}</span></div>
                      </el-row>
                      <el-row style="margin-top: 16px;">
                        <div>报名结束时间: <span style="color: rgb(255, 166, 0);">{{signInTimeData.registrationEndTime}}</span></div>
                      </el-row>
                  </el-form>
                  <el-row type="flex" justify="end">
                      <el-button
                          type="primary"
                          @click="editSignInTimeFormDialogVisible = true"
                          >编辑时间</el-button
                      >
                  </el-row>
              </el-card>
          </el-col>
      </el-row>
      <!-- 轮次信息列表 -->
      <el-card style="margin-top: 15px">
          <el-row class="title_row" align="middle" type="flex">
              <el-col :span="5">
                  <span
                      style="
                          display: -moz-inline-box;
                          display: inline-block;
                          width: 120px;
                      "
                      >轮次信息列表</span
                  >
                  <span style="color: blue; font-size: 14px">{{
                      "已录入" + total + "个轮次"
                  }}</span>
              </el-col>
          </el-row>
          <el-table
              header-cell-class-name="tableHeaderStyle"
              :data="dataList"
              border
              stripe
          >
              <el-table-column label="#" type="index"></el-table-column>
              <el-table-column
                  label="轮次名称"
                  prop="name"
                  min-width="13%"
              ></el-table-column>
              <el-table-column
                  label="届"
                  prop="ibcSession"
                  :formatter="sessionFormatter"
                  min-width="5%"
              >
              </el-table-column>
              <el-table-column
                  label="赛季"
                  prop="ibcSeason"
                  :formatter="seasonFormatter"
                  min-width="7%"
              >
              </el-table-column>
              <el-table-column
                  label="是否当前赛季"
                  prop="isIng"
                  width="110px"
              >
                  <template slot-scope="scope">
                    <el-tag
                          type="success"
                          v-if="scope.row.isIng + '' === '1'"
                          size="mini"
                          >当前赛季</el-tag
                      >
                      <el-tag
                          type="danger"
                          v-else
                          size="mini"
                          >非当前赛季</el-tag
                      >
                  </template>
              </el-table-column>
              <el-table-column
                  label="轮次"
                  prop="ibcRound"
                  :formatter="roundFormatter"
                  min-width="7%"
              >
              </el-table-column>
              <el-table-column
                  label="是否发布成绩"
                  prop="isTop"
                  min-width="8%"
              >
                  <template slot-scope="scope">
                      <el-tag
                          type="danger"
                          v-if="scope.row.isTop + '' === '0'"
                          size="mini"
                          >未发布</el-tag
                      >
                      <el-tag
                          type="success"
                          v-else-if="scope.row.isTop + '' === '1'"
                          size="mini"
                          >已发布</el-tag
                      >
                      <span v-else>-</span>
                  </template>
              </el-table-column>
              <el-table-column
                  label="开始时间"
                  prop="roundStartTime"
                  min-width="12%"
              >
              </el-table-column>
              <el-table-column
                  label="结束时间"
                  prop="roundEndTime"
                  min-width="12%"
              ></el-table-column>
              <el-table-column
                  label="通知"
                  prop="notice"
                  :show-overflow-tooltip="true"
                  min-width="30%"
              ></el-table-column>
              <el-table-column label="操作" min-width="8%">
                  <template slot-scope="scope">
                      <el-button
                          type="text"
                          icon="el-icon-edit"
                          size="small"
                          @click="changeEditDialog(scope.row)"
                          >修改</el-button
                      >
                  </template>
              </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryForm.pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
      </el-card>
      <!-- 编辑信息的dialog -->
      <el-dialog
          title="修改轮次信息"
          :visible.sync="editInfoDialogVisible"
          width="40%"
          @close="editInfoDialogClose"
      >
          <el-form
              :model="editInfoForm"
              label-width="150px"
              ref="editInfoFormRef"
              :rules="editInfoRules"
          >
              <el-form-item label="轮次名称" prop="name" style="width: 80%">
                  <el-input
                      v-model="editInfoForm.name"
                      placeholder="请输入轮次名称"
                  ></el-input>
              </el-form-item>
              <el-form-item label="提示信息" prop="notice" style="width: 80%">
                  <el-input
                      type="textarea"
                      v-model="editInfoForm.notice"
                      placeholder="请输入提示信息"
                  ></el-input>
                  <span style="color: red"
                      >注*该提示信息将会在“上一轮晋级界面”和“本轮未开始前”展示，如是第一轮线上答题，请提前确认该轮次是否已有试卷，避免小程序出现”试卷不存在现象“</span
                  >
              </el-form-item>
              <el-form-item
                  label="比赛开始时间"
                  prop="roundStartTime"
                  style="width: 80%"
              >
              <!-- :picker-options="pickerOptions" -->
                  <el-date-picker
                      v-model="editInfoForm.roundStartTime"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
              <el-form-item
                  label="比赛结束时间"
                  prop="roundEndTime"
                  style="width: 80%"
              >
              <!-- :picker-options="pickerOptions" -->
                  <el-date-picker
                      v-model="editInfoForm.roundEndTime"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="editInfoDialogVisible = false"
                  >取 消</el-button
              >
              <el-button type="primary" @click="editInfo">保 存</el-button>
          </span>
      </el-dialog>
      <!-- 修改当前赛季dialog -->
      <el-dialog
          title="修改当前赛季"
          :visible.sync="editSeasonFormDialogVisible"
          width="30%"
      >
          <el-form
              :model="editSeasonForm"
              label-width="80px"
              ref="editSeasonFormRef"
              :rules="editSeasonFormRules"
          >
              <el-form-item label="届" prop="ibcSession">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editSeasonForm.ibcSession"
                      clearable
                  >
                      <el-option
                          v-for="item in dict_ibcSession"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="季度" prop="ibcSeason">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editSeasonForm.ibcSeason"
                      clearable
                  >
                      <el-option
                          v-for="item in dict_ibcSeason"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="editSeasonFormDialogVisible = false"
                  >取 消</el-button
              >
              <el-button type="primary" @click="editSeason">修 改</el-button>
          </span>
      </el-dialog>
      <!-- 编辑信息的dialog -->
      <el-dialog
          title="编辑赛季报名时间"
          :visible.sync="editSignInTimeFormDialogVisible"
          width="40%"
      >
          <el-form
              :model="signInFormModel"
              label-width="150px"
              ref="signInFormModelRef"
              :rules="signInFormRules"
          >
              <el-form-item
                  label="报名开始时间"
                  prop="registrationStartTime"
                  style="width: 80%"
              >
              <!-- :picker-options="pickerOptions" -->
                  <el-date-picker
                      v-model="signInFormModel.registrationStartTime"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
              <el-form-item
                  label="报名结束时间"
                  prop="registrationEndTime"
                  style="width: 80%"
              >
              <!-- :picker-options="pickerOptions" -->
                  <el-date-picker
                      v-model="signInFormModel.registrationEndTime"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="editSignInTimeFormDialogVisible = false"
                  >取 消</el-button
              >
              <el-button type="primary" @click="editSignInTimeInfo"
                  >保 存</el-button
              >
          </span>
      </el-dialog>
  </div>
</template>

<script>
import { getIbcSessionList, updateIbcSeasonList, switchIbcSeason, getDictList, getSeasonRegistrationStartAndEndTimes, queryIbcSeason } from '@/http/api'
export default {
  data () {
    return {
      selectSeason: '',
      signInTimeData: {},
      editSignInTimeFormDialogVisible: false,
      signInFormModel: {
        registrationStartTime: '',
        registrationEndTime: ''
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      editSeasonFormDialogVisible: false,
      editSeasonForm: {
        ibcSession: '',
        ibcSeason: ''
      },
      dataList: [],
      editInfoDialogVisible: false,
      total: 0,
      dict_ibcSeason: [],
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$ibcManager.ibcSeason
      },
      signInFormRules: {
        registrationStartTime: [{ required: true, message: '请选择报名开始时间', trigger: 'change' }],
        registrationEndTime: [{ required: true, message: '请选择报名结束时间', trigger: 'change' }]
      },
      editSeasonFormRules: {
        ibcSession: [{ required: true, message: '请选择当前届', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择当前赛季', trigger: 'change' }]
      },
      editInfoRules: {
        name: [{ required: true, message: '请输入轮次名称', trigger: 'blur' }],
        notice: [{ required: true, message: '请输入提示信息', trigger: 'blur' }],
        roundStartTime: [{ required: true, message: '请选择比赛开始时间', trigger: 'change' }],
        roundEndTime: [{ required: true, message: '请选择比赛结束时间', trigger: 'change' }]
      },
      editInfoForm: {
        ibcSession: this.$ibcManager.ibcSession,
        ibcSeason: this.$ibcManager.ibcSeason,
        roundStartTime: '',
        roundEndTime: '',
        id: '',
        notice: ''
      }
    }
  },
  created () {
    this.getAcquiesceData()
  },
  methods: {
  // groupFormatter (data) {
  //   console.log('data', data)
  //   var temp = '未设置'
  //   for (const key in this.dict_sspcnGroup) {
  //     if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
  //       const element = this.dict_sspcnGroup[key]
  //       if (element.dictValue === data.grade + '') {
  //         temp = element.dictLabel
  //       }
  //     }
  //   }
  //   return temp
  // },
    sessionFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcSession) {
        if (Object.hasOwnProperty.call(this.dict_ibcSession, key)) {
          const element = this.dict_ibcSession[key]
          if (element.dictValue === data.ibcSession + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    seasonFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcSeason) {
        if (Object.hasOwnProperty.call(this.dict_ibcSeason, key)) {
          const element = this.dict_ibcSeason[key]
          if (element.dictValue === data.ibcSeason + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    selectSeasonFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcSeason) {
        if (Object.hasOwnProperty.call(this.dict_ibcSeason, key)) {
          const element = this.dict_ibcSeason[key]
          if (element.dictValue === data.ibcSeason + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    roundFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_ibcRound) {
        if (Object.hasOwnProperty.call(this.dict_ibcRound, key)) {
          const element = this.dict_ibcRound[key]
          if (element.dictValue === data.ibcRound + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    getAcquiesceData () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        this.queryForm = { ...this.queryForm, ...newObj }
        this.getSeasonData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getSeasonData () {
      getDictList({ dictType: 'ibc_season' }).then((res) => {
        this.dict_ibcSeason = res.data
        this.selectSeason = this.selectSeasonFormatter({ ibcSeason: this.queryForm.ibcSeason })
        this.getSeasonSignTime()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取季度比赛开始时间
    getSeasonSignTime () {
      getSeasonRegistrationStartAndEndTimes(this.queryForm).then((res) => {
        this.signInTimeData = res.data
        this.signInFormModel = res.data
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取轮次列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getIbcSessionList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
        this.selectSeason = this.selectSeasonFormatter({ ibcSeason: this.queryForm.ibcSeason })
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 季度修改点击事件
    seasonChange (val) {
      this.selectSeason = this.selectSeasonFormatter({ ibcSeason: val })
      this.getSeasonSignTime()
      this.getDataList('search')
    },
    // 修改按钮点击事件
    changeEditDialog (scope) {
      this.editInfoForm.id = scope.id
      this.editInfoForm.notice = scope.notice
      this.editInfoForm.name = scope.name
      this.editInfoForm.roundStartTime = scope.roundStartTime
      this.editInfoForm.roundEndTime = scope.roundEndTime
      this.editInfoDialogVisible = true
    },
    // 确认修改轮次点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        if (!this.checkTimeRule()) {
          return this.$message.warning('比赛开始时间不得大于或等于结束时间')
        }
        this.editInfoForm.ibcSession = this.queryForm.ibcSession
        this.editInfoForm.ibcSeason = this.queryForm.ibcSeason
        updateIbcSeasonList(this.editInfoForm).then((res) => {
          this.$message.success('修改成功')
          this.editInfoDialogVisible = false
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 修改轮次弹窗关闭
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    // 时间选择规则
    checkTimeRule () {
      if (this.editInfoForm.roundStartTime > this.editInfoForm.roundEndTime || this.editInfoForm.roundStartTime === this.editInfoForm.roundEndTime) {
        return false
      }
      return true
    },
    // 修改赛季报名时间
    editSignInTimeInfo () {
      updateIbcSeasonList({ ...this.queryForm, ...this.signInFormModel }).then((res) => {
        this.$message.success('编辑成功')
        this.editSignInTimeFormDialogVisible = false
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改当前届、赛季
    editSeason () {
      this.$refs.editSeasonFormRef.validate(async valid => {
        if (!valid) return
        console.log('this.editInfoForm', this.editInfoForm)
        switchIbcSeason(this.editSeasonForm).then((res) => {
          this.$message.success('修改成功')
          this.editSeasonFormDialogVisible = false
          this.getAcquiesceData()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    }
  }
}
</script>

<style>
</style>
